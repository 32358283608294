import classNames from "classnames";

interface TriangleProps {
  isDisabled: boolean | undefined;
}

const Triangle = ({ isDisabled }: TriangleProps) => (
  <svg
    className="absolute left-0 top-0 hidden size-[22.5px] sme:block"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      className={classNames({
        "fill-secondary-500": !isDisabled,
        "fill-secondary-200": isDisabled,
      })}
      d="M23.5607 1.06066L26.1213 -1.5H22.5H0H-1.5V0V22.5V26.1213L1.06066 23.5607L23.5607 1.06066Z"
      stroke="white"
      strokeWidth="3"
    />
  </svg>
);

export default Triangle;
