import React from "react";
import { twMerge } from "tailwind-merge";

const AnchorButton = ({
  className = "",
  children = undefined,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    className={twMerge(
      className,
      "m-0 inline cursor-pointer border-0 bg-transparent p-0 decoration-0",
    )}
    {...props}
  >
    {children}
  </button>
);

export default AnchorButton;
