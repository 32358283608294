"use client";

import type { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

import LoadingIcon from "@/app/_media/loading.svg";

import { outlineButtonClassNames, colorMappings } from "./classNames";

interface OutlineButtonProps extends ComponentProps<"button"> {
  color?: "primary" | "secondary" | "tertiary" | "neutral";
  loading?: boolean;
}

export default function OutlineButton({
  type = "button",
  className,
  color = "primary",
  loading = false,
  children,
  ...props
}: OutlineButtonProps) {
  const colorClasses = colorMappings[color] || [];

  return (
    <div className="relative">
      <button
        type={type}
        className={twMerge(outlineButtonClassNames, colorClasses, className)}
        {...props}
      >
        {children}
      </button>
      {loading && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <LoadingIcon width={32} />
        </div>
      )}
    </div>
  );
}
