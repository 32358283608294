import Link from "next/link";

export default function LinkOrAnchor({
  href,
  children,
  className = undefined,
}: {
  href: string;
  className?: string;
  children: React.ReactNode;
}) {
  let LinkComponent: string | typeof Link;

  if (href.startsWith("http")) {
    LinkComponent = "a";
  } else {
    LinkComponent = Link;
  }
  return (
    <LinkComponent href={href} className={className}>
      {children}
    </LinkComponent>
  );
}
