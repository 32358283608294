import React from "react";
import { ButtonProps as ReactAriaButtonProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import Icon from "@/app/_components/icon";

import { useIsWhitelabel } from "@/app/_hooks";
import Triangle from "./Triangle";
import { ButtonProps } from "./useButton";

interface ButtonContentProps extends ButtonProps {
  children: ReactAriaButtonProps["children"];
  badge?: number;
}

const Badge = ({ number }: { number: number }) => (
  <span className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-secondary-500 text-xs font-bold text-black">
    {number}
  </span>
);

const ButtonContent = ({
  children,
  size,
  variant,
  isDisabled,
  leadingIcon,
  trailingIcon,
  badge = undefined,
}: ButtonContentProps) => {
  const isWhitelabel = useIsWhitelabel();
  const hasIcon = !!(leadingIcon || trailingIcon);
  const hasBadge = !!badge;
  const iconClassName = twMerge(
    "shrink-0",
    variant === "link" && size === "small" ? "h-5 w-5" : "size-6",
    size === undefined && "h-5 w-5 tablet:h-6 tablet:w-6",
  );

  return (
    <>
      {!isWhitelabel && variant === "primary" && !hasIcon && (
        <Triangle isDisabled={isDisabled} />
      )}
      {leadingIcon && (
        <span className="relative inline-block">
          {hasBadge && <Badge number={badge} />}
          <Icon name={leadingIcon} className={iconClassName} />
        </span>
      )}
      {children}
      {trailingIcon && <Icon name={trailingIcon} className={iconClassName} />}
      {hasBadge && !leadingIcon && <Badge number={badge} />}
    </>
  );
};

export default ButtonContent;
