import React from "react";
import { twMerge } from "tailwind-merge";

interface SectionEyebrowHeadlineProps {
  className?: string;
  children: React.ReactNode;
}

const SectionEyebrowHeadline: React.FC<SectionEyebrowHeadlineProps> = ({
  className = "",
  children,
}) => (
  <div
    className={twMerge(
      "pb-2 text-sm font-semibold text-bluegray-500 desktop:text-lg",
      className,
    )}
  >
    {children}
  </div>
);

export default SectionEyebrowHeadline;
