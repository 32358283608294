import { useParams } from "next/navigation";
import { LeadLineMode } from "../(SME)/(lead-line)/types";

export function useSingleStringParams(): { [key: string]: string } {
  const params = useParams();
  const singleStringParams: { [key: string]: string } = {};

  Object.keys(params).forEach(key => {
    if (Array.isArray(params[key])) {
      throw new Error(
        `Expected parameter ${key} to be a string but found an array.`,
      );
    } else {
      singleStringParams[key] = <string>params[key];
    }
  });

  return singleStringParams;
}

export function getIsWhitelabel(mode: LeadLineMode): boolean {
  return mode === "embed";
}

export const useIsWhitelabel = () => {
  const { mode } = useParams<{ mode: LeadLineMode }>();
  return getIsWhitelabel(mode);
};
