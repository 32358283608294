import { twMerge } from "tailwind-merge";

interface SectionProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ id, className, children }) => (
  <section
    id={id}
    className={twMerge(
      "flex flex-col gap-y-5 px-4 tablet:px-5 laptop:gap-y-6 laptop:px-8 max:px-[7.5rem]",
      className,
    )}
  >
    {children}
  </section>
);

export default Section;
