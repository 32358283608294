/* eslint-disable react/require-default-props */
/* https://github.com/jsx-eslint/eslint-plugin-react/issues/2856 */

"use client";

import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { TagProps, TagsContextProvider } from "./useTags";

type TagsProps = {
  children: React.ReactNode;
  className?: string;
} & TagProps &
  React.HTMLAttributes<HTMLUListElement>;

const Tags = forwardRef<HTMLUListElement, TagsProps>(
  ({ children, className = "", size, color, icon, ...props }, ref) => (
    <ul
      className={twMerge(
        "flex flex-wrap justify-start gap-x-4 gap-y-2",
        className,
      )}
      ref={ref}
      {...props}
    >
      <TagsContextProvider value={{ size, color, icon }}>
        {children}
      </TagsContextProvider>
    </ul>
  ),
);

Tags.displayName = "Tags";

export default Tags;
