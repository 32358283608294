import React from "react";
import Link, { type LinkProps as NextLinkProps } from "next/link";

import ButtonLink, { ButtonLinkProps } from "./ButtonLink";

const NextLink = ({
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  legacyBehavior,
  ...props
}: NextLinkProps & ButtonLinkProps) => (
  <Link
    href={href}
    replace={replace}
    scroll={scroll}
    prefetch={prefetch}
    shallow={shallow}
    as={as}
    locale={locale}
    legacyBehavior
    passHref
  >
    <ButtonLink {...props}>{children}</ButtonLink>
  </Link>
);

export default NextLink;
