"use client";

import { createContext, useContext, useMemo } from "react";

import { IconProps } from "@/app/_components/icon";

export interface TagProps {
  size?: "xs" | "small" | "large";
  color?: "primary" | "secondary" | "tertiary" | "neutral" | "transparent";
  icon?: IconProps["name"];
}

const TagsContext = createContext<TagProps>({});
export const TagsContextProvider = TagsContext.Provider;

export const useTags = (props: TagProps) => {
  const containerProps = useContext(TagsContext);

  return useMemo(
    () => ({
      ...containerProps,
      ...props,
    }),
    [containerProps, props],
  );
};
