interface SectionHeaderProps {
  className?: string;
  children: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  className,
  children,
}) => <div className={className}>{children}</div>;

export default SectionHeader;
