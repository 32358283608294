import React from "react";

import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SectionEyebrowHeadline from "./SectionEyebrowHeadline";
import SectionHeadline from "./SectionHeadline";
import SectionSubHeadline from "./SectionSubHeadline";

interface SimpleSectionProps {
  headline?: string;
  subHeadline?: string | React.ReactNode;
  eyebrowHeadline?: string;
  children?: React.ReactNode;
  isH1Section?: boolean;
  className?: string;
}

const SimpleSection: React.FC<SimpleSectionProps> = ({
  headline = undefined,
  subHeadline = undefined,
  eyebrowHeadline = undefined,
  children = undefined,
  isH1Section = false,
  className = undefined,
}) => (
  <Section className={className}>
    <SectionHeader>
      {eyebrowHeadline && (
        <SectionEyebrowHeadline>{eyebrowHeadline}</SectionEyebrowHeadline>
      )}
      {headline && (
        <SectionHeadline isH1Section={isH1Section}>{headline}</SectionHeadline>
      )}
      {subHeadline && <SectionSubHeadline>{subHeadline}</SectionSubHeadline>}
    </SectionHeader>
    {children}
  </Section>
);

export default SimpleSection;
export {
  Section,
  SectionHeader,
  SectionEyebrowHeadline,
  SectionHeadline,
  SectionSubHeadline,
};
