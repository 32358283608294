import React from "react";
import Headline from "@/app/_components/landingPages/Headline";

interface SectionHeadlineProps {
  className?: string;
  children: React.ReactNode;
  isH1Section?: boolean;
}

const SectionHeadline: React.FC<SectionHeadlineProps> = ({
  className = "",
  children,
  isH1Section = false,
}) => {
  const type = isH1Section ? "h1" : "h2";

  return (
    <Headline className={className} type={type}>
      {children}
    </Headline>
  );
};

export default SectionHeadline;
