"use client";

import { twMerge } from "tailwind-merge";
import type { ComponentProps } from "react";

import { containedButtonClassNames } from "./classNames";
import OutlineButton from "./outlineButton";

interface ContainedButtonProps extends ComponentProps<"button"> {
  loading?: boolean;
}

export default function ContainedButton({
  children,
  className,
  type = "button",
  loading = false,
  ...props
}: ContainedButtonProps) {
  return (
    <OutlineButton
      loading={loading}
      className={twMerge(containedButtonClassNames, className)}
      {...props}
      type={type}
      color="primary"
    >
      {children}
    </OutlineButton>
  );
}
