import React from "react";
import { twMerge } from "tailwind-merge";

interface SectionSubHeadlineProps {
  className?: string;
  children: React.ReactNode;
}

const SectionSubHeadline: React.FC<SectionSubHeadlineProps> = ({
  className = "",
  children,
}) => (
  <div className={twMerge("pt-2.5 text-base font-extrabold", className)}>
    {children}
  </div>
);

export default SectionSubHeadline;
