"use client";

import React, { forwardRef } from "react";
import {
  Link as ReactAriaLink,
  LinkProps as ReactAriaLinkProps,
} from "react-aria-components";

import useButton, { ButtonProps } from "@/app/_components/button/useButton";
import ButtonContent from "@/app/_components/button/ButtonContent";

export interface ButtonLinkProps
  extends ButtonProps,
    Omit<ReactAriaLinkProps, "children" | "className"> {
  // eslint-disable-next-line react/require-default-props
  id?: string;
  children: React.ReactNode;
}

const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ children, id = undefined, ...props }, ref) => {
    const { className, ...buttonProps } = useButton(props);

    return (
      <ReactAriaLink
        ref={ref}
        {...props}
        // @ts-ignore
        id={id}
        className={className}
        {...buttonProps}
      >
        <ButtonContent {...buttonProps}>{children}</ButtonContent>
      </ReactAriaLink>
    );
  },
);

ButtonLink.displayName = "ButtonLink";

export default ButtonLink;
