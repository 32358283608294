"use client";

import { useHasServiceConsent } from "@s-group/react-usercentrics";

import { YOUTUBE_SERVICE_ID } from "@/app/_constants";
import UsercentricsYoutubeConsent from "../usercentricsYoutubeConsent";

export default function WithYoutubeConsent({
  children,
}: {
  children: React.ReactNode;
}) {
  const hasConsent = useHasServiceConsent(YOUTUBE_SERVICE_ID);

  return !hasConsent ? <UsercentricsYoutubeConsent /> : children;
}
