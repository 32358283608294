export const outlineButtonClassNames = `
  relative
  inline-flex
  cursor-pointer
  content-start
  items-center
  justify-center
  border-2
  bg-background
  px-6
  py-4
  text-base
  font-extrabold
  disabled:cursor-not-allowed
  disabled:opacity-50
  active:ring-0
`;

export const containedButtonClassNames = `
  h-[60px]
  bg-primary
  text-background
  focus:outline-none
  focus:ring-2
  focus:ring-primary-100
  focus:ring-offset-2
  active:ring-0 hover:enabled:border-primary-700 hover:enabled:bg-primary-700
  active:enabled:translate-x-px
  active:enabled:translate-y-px
  active:enabled:border-primary-700
  active:enabled:bg-primary-700
  sme:triangle-corner
`;

export const colorMappings = {
  primary: ["border-primary", "text-primary", "hover:enabled:bg-primary-50"],
  secondary: [
    "border-secondary",
    "text-secondary",
    "hover:enabled:bg-secondary-50",
  ],
  tertiary: [
    "border-tertiary",
    "text-tertiary",
    "hover:enabled:bg-tertiary-50",
  ],
  neutral: [
    "border-neutral-800",
    "text-neutral-800",
    "hover:enabled:border-neutral",
    "hover:enabled:text-neutral",
  ],
};
