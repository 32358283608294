import { twMerge } from "tailwind-merge";

interface HeadlineProps {
  className?: string;
  type?: "h1" | "h2";
  children: React.ReactNode;
}

const Headline: React.FC<HeadlineProps> = ({
  className = "",
  type: Element = "h2",
  children,
}) => (
  <Element
    className={twMerge(
      "text-2xl font-black text-text laptop:text-3xl",
      className,
    )}
  >
    {children}
  </Element>
);

export default Headline;
