import { showSecondLayer, acceptService } from "@s-group/react-usercentrics";
import { YOUTUBE_SERVICE_ID } from "@/app/_constants";

import { ContainedButton } from "./buttons";

export default function UsercentricsYoutubeConsent() {
  return (
    <div className="flex h-full border bg-background px-4 py-6 text-center shadow-inner">
      <div className="flex flex-col justify-center gap-4">
        <h3 className="text-lg font-bold leading-5">
          Wir würden Ihnen hier gerne ein Video zeigen.
        </h3>
        <p className="text-sm">
          Wir verwenden YouTube, um Videoinhalte einzubetten. Dieser Service
          kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details
          durch und stimmen Sie der Nutzung des Service zu, um dieses Video
          anzusehen.
        </p>
        <div className="flex justify-center gap-x-6">
          <button
            className="h-auto py-1.5 text-sm text-neutral hover:text-primary"
            onClick={() => showSecondLayer(YOUTUBE_SERVICE_ID)}
          >
            Mehr Informationen
          </button>
          <ContainedButton
            className="h-auto px-4 py-1.5 font-bold before:hidden"
            onClick={() => acceptService(YOUTUBE_SERVICE_ID)}
          >
            Akzeptieren
          </ContainedButton>
        </div>
      </div>
    </div>
  );
}
