import { getImageProps, ImageProps, StaticImageData } from "next/image";
import Head from "next/head";

export type AdaptiveImageScreens =
  | "mobile"
  | "tablet"
  | "laptop"
  | "desktop"
  | "max";

export type AdaptiveImageType = {
  image: StaticImageData | string;
  size: AdaptiveImageScreens;
};

const AdaptiveImage = ({
  className,
  imageClassName = "",
  alt = "Hero image",
  fill = true,
  priority = true,
  preloading = true,
  sizes = "100vw",
  images,
  style = {},
  ...props
}: {
  imageClassName?: string;
  preloading?: boolean;
  images: AdaptiveImageType[];
} & Partial<ImageProps>) => {
  if (images.length === 0) {
    throw new Error("You need to define at least one image");
  }
  const mediaQueries = {
    max: `(min-width: 1600px)`,
    desktop: `(min-width: 1280px)`,
    laptop: `(min-width: 1024px)`,
    tablet: `(min-width: 640px)`,
    mobile: ``,
  };

  const commonPreload = {
    rel: "preload",
    as: "image",
    imageSizes: sizes,
  };
  const common = { alt, fill, priority, sizes, style, ...props };

  const srcSets = images.map(image => {
    const { srcSet, ...rest } = getImageProps({
      ...common,
      src: image.image,
    }).props;
    return { size: image.size, srcSet, ...rest };
  });
  return (
    <>
      {preloading && (
        <Head>
          {srcSets.map(image => (
            <link
              key={image.size}
              {...commonPreload}
              media={mediaQueries[image.size]}
              href={image.src}
              imageSrcSet={image.srcSet}
            />
          ))}
        </Head>
      )}
      <picture className={className}>
        {srcSets.map(image => (
          <source
            key={image.size}
            {...commonPreload}
            media={mediaQueries[image.size]}
            srcSet={image.srcSet}
          />
        ))}
        <img className={imageClassName} {...srcSets[0]} alt={alt} />
      </picture>
    </>
  );
};

export default AdaptiveImage;
